// @flow

import console from '../utils/debug';

import * as environments from '../redux/environments';
import * as features from '../redux/features';
import * as featureControls from '../redux/featureControls';
import * as experiments from '../redux/experiments';
import * as projects from '../redux/projects';
import * as organisation from '../redux/organisation';
import * as subscriptionOutline from '../redux/subscriptionOutline';
import { dispatch } from '../redux';

type MessageFormat = {
  ack: any,
  nack: any,
  body: string,
  command: string,
  headers: {[key:string]: string}
}


export default function onMessage(message: MessageFormat){
  let body = JSON.parse(message.body);

  switch(body.type){
    case('CREATE_ENVIRONMENT_REQUEST_SUCCESS'):
      return dispatch(environments.setEnvironments([body.payload]));
    case('UPDATE_ENVIRONMENT_REQUEST_SUCCESS'):
      return dispatch(environments.setEnvironments([body.payload]));
    case('DELETE_ENVIRONMENT_REQUEST_SUCCESS'):
      return dispatch(environments.removeEnvironment(body.payload.id));
    case('CREATE_FEATURE_REQUEST_SUCCESS'):
      return dispatch(features.setFeatures([body.payload]));
    case('UPDATE_FEATURE_REQUEST_SUCCESS'):
      return dispatch(features.setFeatures([body.payload]));
    case('DELETE_FEATURE_REQUEST_SUCCESS'):
      return dispatch(features.removeFeature(body.payload.id));
    case('CREATE_FEATURE_CONTROLS_REQUEST_SUCCESS'):
      return dispatch(featureControls.setFeatureControls(body.payload));
    case('UPDATE_FEATURE_CONTROLS_REQUEST_SUCCESS'):
      return dispatch(featureControls.setFeatureControls(body.payload));
    case('DELETE_FEATURE_CONTROLS_REQUEST_SUCCESS'):
      return dispatch(featureControls.removeFeatureControls(body.payload.map(featureControl=>featureControl.id)));
    case('CREATE_EXPERIMENT_REQUEST_SUCCESS '):
      return dispatch(experiments.setExperiments([body.payload]));
    case('UPDATE_EXPERIMENT_REQUEST_SUCCESS'):
      return dispatch(experiments.setExperiments([body.payload]));
    case('DELETE_EXPERIMENT_REQUEST_SUCCESS'):
      return dispatch(experiments.removeExperiment(body.payload.id));
    case('UPDATE_SUBSCRIPTION_REQUEST_SUCCESS'):
      return dispatch(subscriptionOutline.setSubscription(body.payload));
    case('UPDATE_PROJECT_REQUEST_SUCCESS'):
      return dispatch(projects.setProjects([body.payload]));
    case('DELETE_PROJECT_REQUEST_SUCCESS'):
      return dispatch(projects.removeProject(body.payload.projectKey));
    case('UPDATE_ORGANISATION_REQUEST_SUCCESS'):
      return dispatch(organisation.setOrganisation([body.payload]));
    default:
      console.warn(`!!! UNHANDLED ONMESSAGE EVENT ${body.type}`, body);
      return;
  }
}
