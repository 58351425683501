// @flow
import React, { useEffect, useState } from 'react';

import {useSelector, shallowEqual, useDispatch} from 'react-redux';

import { useFeatureflow } from 'react-featureflow-client';

import {convertStringToKey} from '../utils/formParsers'

import {createSelector} from 'reselect';

import {Link} from 'react-router';

import {getRoute} from '../utils/routing';

import {FaSpinner} from 'react-icons/lib/fa';

import * as TutorialCode from '../components/TutorialCode';
import store from 'store';
import {LocalForm, Control} from 'react-redux-form';
import {FormControl, Button, DropdownButton, MenuItem} from 'react-bootstrap';
import {numberLessThanLimit} from "../utils/limits";
import UpgradeLink from '../components/UpgradeLink';

import {
    useGetEnvironmentByProjectKeyAndEnvironmentKeyQuery,
    useGetFeatureByProjectKeyAndFeatureKeyQuery,
    useGetFeaturesByProjectKeyQuery,
    useGetProjectByKeyQuery,
    useGetProjectsQuery,
    useCreateFeatureMutation
} from "../api/featureflowApi";

const steps = [
    'CREATE_FEATURE',
    'ADD_CODE',
    'TEST_FEATURE',
    'FINISH'
];


const Tutorial = (props: any) => {
    const dispatch = useDispatch();
    const featureflow = useFeatureflow();
    const limits = useSelector((state => state.subscriptionOutline.limits));
    const { projectKey, environmentKey } = props.params;    
    const localStorageKey = `ff:tutorial:${projectKey}:${environmentKey}`;    
    const [state, setState] = useState({
        step: 'CREATE_FEATURE',
        featureName: undefined,
        featureKey: undefined,
        feature: undefined,  
        featureAvailable: false,      
        language: 'Java',
        ...store.get(localStorageKey),
        featureFormValues: {},
        submitting: false,
    });

    //Queries
    const { data: project, error: projectError, isLoading: projectIsLoading } = useGetProjectByKeyQuery(projectKey);
    const { data: environment, error: environmentError, isLoading: environmentIsLoading } = useGetEnvironmentByProjectKeyAndEnvironmentKeyQuery({projectKey, environmentKey}, {skip: !projectKey || !environmentKey});
    const { data: features, error: featuresError, isLoading: featuresIsLoading, refetch: refetchFeatures } = useGetFeaturesByProjectKeyQuery({projectKey});
    const { data: feature, error: featureError, isLoading: featureIsLoading } 
        = useGetFeatureByProjectKeyAndFeatureKeyQuery(
            {
                projectKey, 
                featureKey: state.featureKey
            }, 
            {
                skip: !state.featureKey || state.featureAvailable,
                pollingInterval: 3000,
            });
    //Mutations
    const [createFeature, { isLoading: createIsLoading, isSuccess: createIsSuccess, isError: createIsError, error: createError }] = useCreateFeatureMutation();
    const saveProgress = () => {
        store.set(localStorageKey, 
        {
            step: state.step,
            featureKey: state.featureKey,
            featureName: state.featureName,
            language: state.language
        })
    }

    useEffect(() => {
        if(feature && (!state.feature || state.feature.id !== feature.id)){
            setState({
                ...state,
                feature
            })
        }
    }, [feature]);

    useEffect(() => {
        if(createIsSuccess && feature){
            setState({
                ...state,
                submitting: false,
                feature: feature,
                step: 'ADD_CODE'
            });
        }
    }, [createIsSuccess, feature]);

    useEffect(() => {
        if(environment && feature && feature.controls[environment.key]){
            const featureAvailable = feature.controls[environment.key].available || false;
            setState({
                ...state,
                featureAvailable,
            })
        }
     }, [feature]);

    //  useEffect(() => {
        
    //     if(!featureIsLoading && feature){            
    //         setState({
    //             ...state,
    //             feature,
    //         })
    //     }
    //  }, [featureIsLoading]);

    useEffect(() => {
        saveProgress();
     }, [state.step, state.feature, state.language]);

    const resetProgress = () => {        
        setState({
            ...state,
            step: 'CREATE_FEATURE',        
            environment: undefined,
            feature: undefined,
            featureKey: undefined,
            featureName: undefined,
            language: 'Java',
            featureFormValues: {},
            submitting: false,
            featureAvailable: false,
        });               
    }

    const renderStepHeader = (index, text) => {
        const currentStep = steps.indexOf(state.step) + 1;
        let color = '#333';
        if (currentStep < index) {
            color = '#ccc';
        } else if (currentStep > index) {
            color = '#52cd1e';
        }
        return (
            <h3>
        <span style={{
            borderRadius: 26,
            height: 26,
            width: 26,
            border: `2px solid ${color}`,
            display: 'inline-block',
            textAlign: 'center',
            marginRight: 5,
            fontSize: 20,
            lineHeight: '22px',
            color
        }}>
          {index}
        </span>
                {text}
            </h3>
        )
    }

    const renderCreateFeature = (index) => {
        const header = renderStepHeader(index, 'Create a Feature');
        if (!state.featureKey || !state.feature || state.step === 'CREATE_FEATURE') {
            const name = state.featureFormValues.name || '';
            return (
                <div>

                    {header}
                    <LocalForm onChange={(values) => setState({...state, featureFormValues: values})}
                               onSubmit={createFeatureStep}>
                        <p>Create a feature name</p>
                        <Control component={FormControl}
                                 model=".name"
                                 bsSize="sm"
                                 placeholder="Name your feature..."
                                 style={{marginBottom: 10}}
                        />

                        {name.length > 0 && <p>
                            Will create a featureKey <span
                            style={{background: '#eee', padding: '0 5px'}}>{convertStringToKey(name)}</span>
                        </p>}

                        <Control component={Button}
                                 model="."
                                 type="submit"
                                 bsSize="sm"
                                 disabled={name.length <= 0 || state.submitting}>
                            {state.submitting ? 'Creating Feature' : 'Create Feature'}
                        </Control>
                        {
                            // !numberLessThanLimit(features.length, limits.maxFeatures) &&
                            // <div className="row">
                            //     <div className="col-lg-12">
                            //         <p style={{marginTop: 10}}>
                            //             Only {limits.maxFeatures} featurelimits.maxFeatures !== 1 && 's'} allowed. <UpgradeLink/>
                            //         </p>
                            //     </div>
                            // </div>
                        }
                    </LocalForm>
                </div>
            )
        }
        else {
            return (
                <div>
                    {header}
                    <p>
                        Feature created {state.feature.name} with key <span
                        style={{background: '#eee', padding: '0 5px'}}>{state.feature.key}</span>
                    </p>
                </div>
            )
        }
    }

    const createFeatureStep = (value: any) => {
        setState({
            ...state, 
            submitting: true
        });
        let payload = {
            name: value.name.trim(),
            key: convertStringToKey(value.name || ''),
            projectKey: environment.projectKey,
            description: '',
            variants: [{
                key: 'on',
                name: 'On',
            }, {
                key: 'off',
                name: 'off'
            }]
        };

        let feature = features.filter(feature => feature.key === payload.key)[0];

        if (feature) {
            setState({
                ...state,
                submitting: false,
                featureKey: payload.key,
                featureName: payload.name,                
                feature: feature,
                featureAvailable: feature.isAvailable,
                step: 'ADD_CODE'
            });
        }
        else {
            setState({
                ...state,
                submitting: false,                
                featureKey: payload.key,
                featureName: payload.name,
                step: 'ADD_CODE'
            });
            createFeature(payload); 
        }

    }

    const renderCodeLanguage = () => {
        const apiKey = environment.apiKey;
        const featureKey = state.featureKey || '';

        let addCodeInstructions;
        if (TutorialCode.default[state.language]) {
            addCodeInstructions = TutorialCode.default[state.language].instructions(apiKey, featureKey);
        }
        else {
            addCodeInstructions = (
                <div>
                    Sorry, our tutorial for {state.language} is not yet available.
                </div>
            )
        }

        return (
            <div>
                Select a Language
                <DropdownButton bsStyle={'primary'}
                                id="code-language-select"
                                style={{margin: '0 10px 5px'}}
                                bsSize="sm"
                                onSelect={(key) => {
                                    setState({...state, language: key})
                                }}
                                title={state.language}
                >
                    {Object.keys(TutorialCode.default).map(key => <MenuItem eventKey={key}
                                                                            key={key}> {key} </MenuItem>)}
                </DropdownButton>
                <Button bsSize="xs" onClick={() => resetProgress()}> Reset tutorial</Button>

                {addCodeInstructions}
            </div>
        )
    }


    const renderAddCode = (index) => {
        const currentStepIndex = steps.indexOf(state.step) + 1;
        const showCodeSteps = currentStepIndex >= index;
        const showCurrentStepButton = currentStepIndex === index;
        return (
            <div>
                {renderStepHeader(index, 'Add the Code')}
                {showCodeSteps && renderCodeLanguage()}
                {showCurrentStepButton && <p>
                    <Button onClick={() => {
                        setState({...state, step: 'TEST_FEATURE'});
                    }}>Done, continue</Button>
                </p>}
            </div>
        );
    }


    const renderTestFeature = (index) => {
        const apiKey = environment.apiKey;
        const featureKey = state.featureKey;

        const currentStepIndex = steps.indexOf(state.step) + 1;
        // const featureAvailable = state.feature && state.feature.controls[environment.key].available || false;
        // setState({
        //     ...state,
        //     featureAvailable
        // })
        let featureControl = feature && feature.controls[environment.key] || {available: false};
        const header = renderStepHeader(index, 'Test your feature');

        let runCodeInstructions;
        if (TutorialCode.default[state.language]) {
            runCodeInstructions = TutorialCode.default[state.language].runCommand(apiKey, featureKey);
        }

        if (currentStepIndex < index) {
            //I'm waiting
            return <div>{header}</div>
        }
        else if (currentStepIndex === index && feature) {
            if (state.featureAvailable) {
                setState({
                    ...state, 
                    step: 'FINISH'
                });
            }
            return (
                <div>
                    {header}
                    <p>Waiting to see <b>{feature.name}</b> in your environment, follow the steps above and
                        run the command. <FaSpinner className="fa-spin"/></p>
                    {runCodeInstructions}
                </div>
            )
        }
        else {
            return (
                <div>
                    {header}
                    <p>{state.feature.name} has been seen in your environment</p>
                </div>
            )
        }
    }

    const renderFinished = (index) => {
        const currentStepIndex = steps.indexOf(state.step) + 1;
        const header = renderStepHeader(index, 'Manage your feature');
        if (index === currentStepIndex) {
            return (
                <div>
                    {header}
                    <p>Now you have finished creating a feature you can go and manage it.</p>
                    <Link to={getRoute("./features")}>Click here to go to your environment</Link>
                </div>
            )
        }
        else {
            return (
                <div>
                    {header}
                </div>
            )
        }

    }
    if(projectIsLoading || environmentIsLoading || featuresIsLoading){
        return <div>Loading...</div>
    }
    return (
        <div>
            <h2>Tutorial</h2>
            <p>
                This tutorial will take you through the basics of setting up the featureflow SDK. <br/>
                We will be using the <b>{project.name}</b> project's <b>{environment.name}</b> environment.
            </p>
            {renderCreateFeature(1)}
            {renderAddCode(2)}
            {renderTestFeature(3)}
            {renderFinished(4)}
        </div>
    );
}

export default Tutorial